.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #f2f2f2;
  height: 80px !important;
  width: 100px !important;
  border-radius: 4px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: 30px;
  object-fit: cover;
  
}

.carouselContent span {
  color: #8daa6a !important;
}


.activeClass {
  /* background: #F2F2F2; */
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: 1.2px solid #8daa6a;
  height: 80px;
  border-radius: 8px;
}


@media (max-width: 961px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .swiper-slide {
    width: 80px !important;
  }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
