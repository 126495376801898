.smallText {
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #4c6b28;
}

.smallText2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #333333;
}
.icon-border {
  background: #f1f1f1;
}

.search ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6b7280;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  padding-bottom: 10px !important;
}

.smallTextColor {
  font-size: 14px;
  line-height: 160%;
  color: #45444b;
}

.smallTextColor2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #333333;
}

.singleText {
  font-size: 18px;
}

.grid-container {
  /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px; */
  width: 80%;
}

.actionButton {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-align: center;
  letter-spacing: 0.1em;
  /* Gray 6 */
  color: #f2f2f2 !important;
  background: #8daa6a !important;
  border-radius: 12px;
  z-index: 9999;
  /* backdrop-filter: blur(40px) !important; */
  position: fixed;
  /* left: 100px; */
  top: 90vh;
  width: 50%;
  padding: 10px;
}

@media (max-width: 961px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .actionButton {
    width: 90% !important;
  }
}

.prentBasketIcon {
  z-index: -9999;
  position: relative;
}

.boxWidth {
  width: 80px;
  height: 80px;
}

.box {
  width: 80px;
  height: 80px;
  background: #f2f2f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.grainText {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #8daa6a;
}

.handPickText {
  font-weight: 700;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #333333;
}

.seeAllText {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #73964c;
}

.bg-hero {
  background-image: url(../../assets/basketImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 240px;
  border-radius: 8px 8px 0px 0px;
}

hr {
  height: 3px;
  background: #73964c;
}

.searchText {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #4f4f4f;
}

.showwingResuiltText {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
