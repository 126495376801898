@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  font-family: "Roboto";
  letter-spacing: 0.1rem;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 5% auto;
  width: 635px;
  height: 430px;
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
}

.item {
  width: 50%;
  position: relative;
}

.item-image {
  height: 430px;
  width: 100%;
  object-fit: cover;
}

.overlay-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background-color: #303030;
  overflow: hidden;
  z-index: 1;
}

.item-details {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  margin-left: 20px;
  color: #84a17d;
  text-align: left;
}

.item-details__title {
  font-size: 22px;
}

.item-details__amount {
  font-weight: bolder;
}

.checkout {
  background: #84a17d; /* fallback for old browsers */

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 430px;
  width: 50%;
}

.checkout-form {
  padding: 20px 20px;
}

.checkout-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.checkout-field label {
  text-align: left;
  color: #e0eafc;
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.checkout-field input {
  background-color: transparent;
  border: 1px solid #cecece;
  border-radius: 5px;
  color: #e0eafc;
  height: 35px;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #bfbfbf;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
}

body {
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cursor {
  cursor: pointer;
}

.pac-container {
  background-color: #151515;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pac-item,
.pac-item-query {
  color: #f7f7f7;
}
.pac-item:hover {
  background: #6666;
}

.customTooltip * {
  color: #ffffff;
  text-align: center;
  background-color: #739a2e;
  padding: 5px;
  font-size: 18px;
}

.customTooltip .introjs-tooltip-title {
  color: #white;
}

.introjs-nextbutton {
  background-color: white !important; /* Tailwind green-500 */
  color: #739a2e !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
}

.introjs-skipbutton {
  color: #ffffff !important; /* Tailwind red-500 */
}
